import { useTranslation } from "react-i18next";

import mgLoc from "../../assets/images/moneygram-location.png";

const FaqSection = () => {
  const { t, i18n } = useTranslation();
  const myLng = i18n.language.substring(0, 2);
  const country = "Moldova"; 
  return (
    <div className="faq-section" id="faqs">
      <div className="faq-section-wrapper" id="myFAQsectionWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="heading">
                <h1 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-67">
                  {t("faqGeneral::header")}
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2 col-10 offset-1">
              <div className="faq-section-content">
                <div className="accordion accordion-1" id="accordionFAQ">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOne"
                        aria-expanded="true"
                        aria-controls="faqOne"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::eligibleQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="faqHeadingOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t('Eligibility::Description::General2')}<br/><br/>
                        {t('Eligibility::Criteria::Moldova::dueConflict')}<br/><br/>
                        {t('Eligibility::Criteria::Moldova::currentLocation')} <br/><br/>
                        {t('Eligibility::Criteria::Moldova::category')} <br/>
                        {t('Eligibility::Criteria::Moldova::prio1')} <br/>
                        {t('Eligibility::Criteria::Moldova::prio2')} <br/>
                        {t('Eligibility::Criteria::Moldova::prio3')} <br/><br/>
                        {t('Eligibility::Criteria::Moldova::noteDocs')} <br/><br/>
                        {t('Eligibility::Criteria::Moldova::priodisclaimer')} 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwo"
                        aria-expanded="true"
                        aria-controls="faqTwo"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::focalpointQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::focalpointA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThree"
                        aria-expanded="true"
                        aria-controls="faqThree"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::applyQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThree"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>{t("faqGeneral::applyA")}</p>
                        <div className="row">
                          <div className="button-group button-group-center">
                            <div>
                              <a href="https://apps.apple.com/app/підтримка/id1619005116">
                                <img
                                  className="p-2"
                                  src={`../assets/images/app-store-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                            <div>
                              <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                                <img
                                  className="p-2"
                                  src={`../assets/images/google-play-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFour"
                        aria-expanded="true"
                        aria-controls="faqFour"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::transferValueQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFour"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p> {t("faqGeneral::transferValueA::Moldova")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSeven"
                        aria-expanded="true"
                        aria-controls="faqSeven"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::bot::durationQ"
                          )}{" "}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSeven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::durationA::Moldova"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEight"
                        aria-expanded="true"
                        aria-controls="faqEight"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bot::registrationConfirmationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEight"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::registrationConfirmationAnswers::Moldova"
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqNine"
                        aria-expanded="true"
                        aria-controls="faqNine"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::duplicateRegistrationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingNine"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::duplicateRegistrationA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*el */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTen"
                        aria-expanded="true"
                        aria-controls="faqTen"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::noPhoneQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::noPhoneA::General")}<br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEleven"
                        aria-expanded="true"
                        aria-controls="faqEleven"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::documentsQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEleven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::documentsA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwelve">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwelve"
                        aria-expanded="true"
                        aria-controls="faqTwelve"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::cashUseQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwelve"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::cashUseA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThirteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThirteen"
                        aria-expanded="true"
                        aria-controls="faqThirteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::dataQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThirteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThirteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                            {t("faqGeneral::dataA")}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFourTeen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFourteen"
                        aria-expanded="true"
                        aria-controls="faqFourteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::viberDeletedQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFourteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFourTeen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body ">
                        <p>{t("faqGeneral::viberDeletedA1")}</p>
                        <p>{t("faqGeneral::viberDeletedA2")}</p>
                        <p>{t("faqGeneral::viberDeletedA3")}</p>
                        <p>{t("faqGeneral::viberDeletedA4")}</p>
                        <p>{t("faqGeneral::viberDeletedA5")}</p>
                      </div>
                    </div>
                  </div> */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFifteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFifteen"
                        aria-expanded="true"
                        aria-controls="faqFifteen"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::deliveryQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFifteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFifteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                          <p>{t("faqGeneral::deliveryA")}</p>
                      </div>
                    </div>
                  </div>
                  {/* Where can I find my nearest MoneyGram agent location? */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeen"
                        aria-expanded="true"
                        aria-controls="faqSevenTeen"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::mgLocationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p> {t("faqGeneral::mgLocationA")}</p>
                        <img src={mgLoc} alt="" />
                      </div>
                    </div>
                  </div>
        
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeenX">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeenX"
                        aria-expanded="true"
                        aria-controls="faqSevenTeenX"
                      >
                        <h4 className="c-dark">
                       {t('faqGeneral::bot::digitalPickupQ')}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeenX"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeenX"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>{t('faqGeneral::bot::digitalPickupA1')}</p>
                        <p>{t('faqGeneral::bot::digitalPickupA2')} <a href="https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf">https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf</a></p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeenM">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeenM"
                        aria-expanded="true"
                        aria-controls="faqSevenTeenM"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bot::digitalPickupErrorQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeenM"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeenM"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t("faqGeneral::bot::digitalPickupErrorA")}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/*
I lost my MoneyGram code
 */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingighteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEigteeighteen"
                        aria-expanded="true"
                        aria-controls="faqEigteeighteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::codeLostQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEigteeighteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEigteeighteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::codeLostA"
                          )}
                        </p> 
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwenty">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwenty"
                        aria-expanded="true"
                        aria-controls="faqTwenty"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::euroQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwenty"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwenty"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::euroA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/*
I do not have a bank account and would like to sign up for one to receive a bank transfer.
 */}
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwentyone">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwentyone"
                        aria-expanded="true"
                        aria-controls="faqTwentyone"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bankAccountQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwentyone"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwentyone"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                      <p>
                          {" "}
                          {t(
                            "faqGeneral::bankAccountQ"
                          )}
                        </p>
                        <p>{t("faqGeneral::bankAccountA2")}</p>
                        <p><a href="https://youtu.be/qECYzG0RpaU">https://youtu.be/qECYzG0RpaU .</a></p>
                      </div>
                    </div>
                  </div> */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwentySix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwentySix"
                        aria-expanded="true"
                        aria-controls="faqTwentySix"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::stayInCountryQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwentySix"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwentySix"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::stayInCountry::Moldova"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
