import {useTranslation} from "react-i18next";

const FeatureStart = () => {
  const {t} = useTranslation();

  return (
    <div className="features features-padding-1" id="eligibility">
      <div className="features-wrapper" id="mySelectedFeatures">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="pattern-image">
                <div className="background-layout">
                  <div className="box-1 box-image"
                       style={{backgroundImage: `url('../assets/images/app-testing-1.JPG')`}}>
                    <div className="box-1-1 bg-purple"/>
                    <div className="box-1-2 bg-red"/>
                  </div>
                  <div className="box-2 bg-blue">
                    <div className="box-2-1 bg-blue"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="features-content">

                <h2 className="c-dark lt-60"> {t('Eligibility::who')}</h2>
                <p>
                  {t('Eligibility::Description::General2')}<br/><br/>
                  {t('Eligibility::Criteria::Romania::dueConflict')}<br/><br/>
                  {t('Eligibility::Criteria::Romania::currentLocation')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::otherOrg')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::category')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::categorya')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::categoryb')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::categoryc')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::categoryd')} <br/><br/>
                  {t('Eligibility::Criteria::Romania::categorye')} <br/><br/>
                  {/* {t('Eligibility::Criteria::Romania::category')} <br/>
                  {t('Eligibility::Criteria::Romania::categorya')} <br/>
                  {t('Eligibility::Criteria::Romania::categoryb')} <br/>
                  {t('Eligibility::Criteria::Romania::categoryc')} <br/>
                  {t('Eligibility::Criteria::Romania::categoryd')} <br/>
                  {t('Eligibility::Criteria::Romania::categorye')} <br/>
                  {t('Eligibility::Criteria::Romania::categoryf')} <br/>
                  {t('Eligibility::Criteria::Romania::categoryg')} <br/> <br/> */}
                  {t('Eligibility::Criteria::Romania::disclaimer1')}<br/><br/>
                  {t('Eligibility::Criteria::Romania::disclaimer2')}<br/><br/>
                  {t('Eligibility::Criteria::Romania::disclaimer3')}<br/><br/>
                  {t('Eligibility::Criteria::Romania::disclaimer4')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureStart;