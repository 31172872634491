import { useTranslation } from "react-i18next";

const FeatureStart = () => {
  const { t } = useTranslation();

  return (
    <div className="features features-padding-1" id="eligibility">
      <div className="features-wrapper" id="mySelectedFeatures">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="pattern-image">
                <div className="background-layout">
                  <div
                    className="box-1 box-image"
                    style={{
                      backgroundImage: `url('../assets/images/app-testing-1.JPG')`,
                    }}
                  >
                    <div className="box-1-1 bg-purple" />
                    <div className="box-1-2 bg-red" />
                  </div>
                  <div className="box-2 bg-blue">
                    <div className="box-2-1 bg-blue" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="features-content">
                <h2 className="c-dark lt-60"> {t("Eligibility::who")}</h2>
                <p>
                  {t("Eligibility::Description::GeneralDescription")}
                  <br />
                  <br />
                  {t("Eligibility::Description::General2")}
                  <br />
                  <br />
                  {t("Eligibility::Criteria::Hungary::dueConflict")}
                  <br />
                  <br />
                  {t("Eligibility::Criteria::Hungary::currentLocation")} <br />
                  <br />
                  {t("Eligibility::Criteria::Hungary::categoryNew")} <br />
                  <br />
                  {/* {t("Eligibility::Criteria::Hungary::categorya")} <br />
                  {t("Eligibility::Criteria::Hungary::categoryb")} <br />
                  {t("Eligibility::Criteria::Hungary::categoryc")} <br />
                  {t("Eligibility::Criteria::Hungary::categoryd")} <br />
                  {t("Eligibility::Criteria::Hungary::categorye")} <br /> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureStart;
