import useWindowDimensions from "../../useWindowDimensions";
import {useTranslation} from "react-i18next";

const Header = () => {
  const {width} = useWindowDimensions();
  const {t, i18n} = useTranslation();
  const myLng = i18n.language.substring(0,2);
  const safeguardingImageUrl = "../../assets/images/romania-safeguarding-" + myLng + ".png"

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="header-content t-center" id="MySelectedHeaderContent">
                {i18n.language === "en" && width > 922 ?
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                      id="theSlogan"> {t('Header::title::Poland')} </h1> :
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                      id="theSlogan"> {t('Header::title::Poland')} </h1>}
                <p className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                  <b>{t('Header::subtitle::General')}</b><br/>
                </p>
                <p className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                  {t('Header::subtitle::Poland::applicationclosed')}<br/>
                </p>
                <p className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                  {t('Header::subtitle::Poland::noteclosure')}<br/><br/>
                  {t('Header::subtitle::Poland::amountassistance')}<br/>
                  {<li>{t("faqGeneral::transferValueA::Poland3")}</li>}
                  {<li>{t("faqGeneral::transferValueA::Poland4")}</li>}
                  {<li>{t("faqGeneral::transferValueA::Poland5")}</li>}
                  {<li>{t("faqGeneral::transferValueA::Poland6")}</li>}
                  {<li>{t("faqGeneral::transferValueA::Poland7")}</li>}<br/>
                  {t('Header::subtitle::Poland::moreinfo')}<br/>
                  <a href="https://pck.pl/kontakt/wielkopolski-oddzial-okregowy-pck/">https://pck.pl/kontakt/wielkopolski-oddzial-okregowy-pck/</a><br/>
                  <a href="https://www.pck-poznan.pl/">https://www.pck-poznan.pl/</a><br/>
                  <a href="https://bydgoszcz.pck.pl/">https://bydgoszcz.pck.pl/</a><br/>
                  <a href="https://bydgoszcz.pck.pl/kontakt.html">https://bydgoszcz.pck.pl/kontakt.html</a>
                  <br/><br/>
                </p>
                <div className="header-image m-horz-auto position-relative">
                  <img src="../../assets/images/header-phone-4.png" alt="phone"/>
                  <div className="background-layout">
                    <div className="box-1 bg-black">
                      <div className="box-2 bg-blue-rgba-8">
                        <div className="box-3 bg-grey">
                          <div className="box-4 bg-grey"></div>
                        </div>
                      </div>
                    </div>
                    <div className="box-5 bg-red-rgba-8">
                      <div className="box-6 bg-grey">
                        <div className="box-7 bg-blue"></div>
                        <div className="box-8 bg-grey"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  <img src={safeguardingImageUrl} alt="safeguarding-message" width="100%"/>
                </p>
                <div align="center">
                  <div> <h3><br/>{t('Header::Safeguarding::General::free')}</h3></div>
                  <div> <h3><br/>{t('Header::Safeguarding::General::thirdpartyReport')}</h3></div>
                  <div> <h3><br/>{<li>{t('Header::Safeguarding::Poland')}</li>}</h3></div>
                  <div> <h3><br/>{<li>{t('Header::Safeguarding::General::integrityline')}</li>}</h3></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;