import useWindowDimensions from "../../useWindowDimensions";
import {useTranslation} from "react-i18next";

const Header = () => {
  const {width} = useWindowDimensions();
  const {t, i18n} = useTranslation();
  const myLng = i18n.language.substring(0,2);
  // const availabilityImageUrl = "../../assets/images/SlovakNationalRelease-" + myLng + ".png"
  const safeguardingImageUrl = "../../assets/images/romania-safeguarding-" + myLng + ".png"

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="header-content t-center" id="MySelectedHeaderContent">
                {i18n.language === "en" && width > 922 ?
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                  id="theSlogan">  {t('Header::title::Slovakia')} </h1> :
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                  id="theSlogan"> {t('Header::title::Slovakia')} </h1>} <br/>
                    <h2 className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                    {t('Header::subtitle::Slovakia::title')}</h2>
                    <p className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                      {t('Header::subtitle::Slovakia::intro')}
                    </p>
                <div className="header-image m-horz-auto position-relative">
                  <img src="../../assets/images/header-phone-4.png" alt="phone"/>
                  <div className="background-layout">
                    <div className="box-1 bg-black">
                      <div className="box-2 bg-blue-rgba-8">
                        <div className="box-3 bg-grey">
                          <div className="box-4 bg-grey"></div>
                        </div>
                      </div>
                    </div>
                    <div className="box-5 bg-red-rgba-8">
                      <div className="box-6 bg-grey">
                        <div className="box-7 bg-blue"></div>
                        <div className="box-8 bg-grey"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                <img src={safeguardingImageUrl} alt="safeguarding-message" width="100%"/>
              </p>
              <div align="center">
                <div> <h3><br/>{t('Header::Safeguarding::General::free')}</h3></div>
                <div> <h3><br/>{t('Header::Safeguarding::General::thirdpartyReport')}</h3></div>
                <div> <h3><br/>{<li>{t('Header::Safeguarding::Slovakia')}</li>}</h3></div>
                <div> <h3><br/>{<li>{t('Header::Safeguarding::General::integrityline')}</li>}</h3></div>
              </div>

              {/*<div>*/}
              {/*  <br/><br/><br/><br/>*/}
              {/*  <img src={availabilityImageUrl} width="100%" alt="availability"/>*/}
              {/*</div>*/}
              
                {/* <br/><br/>
                {<h2>
                  {t('Header::Safeguarding::Slovakia')}
                  <a href="https://redcross.sk/ukraine-info-ua" target="_blank" rel="opener">
                  https://redcross.sk/ukraine-info-ua
                </a></h2>}<br/>
              
              <p align="center">
                <iframe height="600" src="https://www.youtube.com/embed/1CYIhXFi3gg"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe><br/>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;