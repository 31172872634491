import {useTranslation} from "react-i18next";

const CtaSection = () => {
    const {t} = useTranslation();

    return (

      <div className="cta-section position-relative">
          <div className="cta-section-wrapper  bg-red">
              <div className="container">
                  <div className="row d-lg-flex align-items-lg-center">
                      <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                          <div className="cta-section-content" id="myCTAsection">
                              <h2 className="c-white">
                                  {t('Header::title::Ukraine')}
                              </h2>
                              <div className="button-group button-group-center">
                                  <div>
                                  </div>
                                  <div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                          <div className="cta-section-image">
                              <img src="../../assets/images/p-ROU0138.jpg" alt="phone"/>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
    )
}

export default CtaSection;