import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation();

  return (
    <footer className="footer position-relative">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="footer-row">
                <div className="footer-detail" id="myFooterDetail">
                  <a href="/">
                    <img src="../../assets/images/montenegro-red-cross.logo.png" alt="Polish-red-cross"/>
                  </a>
                  <a href="/">
                    <img src="../../assets/images/ifrc-logo.png" alt="ifrc"/>
                  </a>
                  <p>{t('Footer::text::Montenegro')}</p>
                  <ul className="social">
                    <li>
                      <a className="button button-blue" rel="noreferrer"
                         href={t('Footer::socials::Montenegro::facebook')}
                         target="_blank">
                                        <span>
                                            <i className="fab fa-facebook-f"/>
                                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="button button-blue" rel="noreferrer"
                         href={t('Footer::socials::Montenegro::linkedin')}
                         target="_blank">
                                        <span>
                                            <i className="fab fa-linkedin"/>
                                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="button button-blue"
                         href={t('Footer::socials::Montenegro::site')} target="_blank"
                         rel="noreferrer">
                                        <span>
                                            <i className="fa fa-plus-circle"/>
                                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="button button-blue"
                         href={t('Footer::socials::Montenegro::instagram')} target="_blank"
                         rel="noreferrer">
                                        <span>
                                            <i className="fab fa-instagram"/>
                                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="footer-copyright c-grey">
                <h6>{t('Footer::dynamic_copyright_ifrc', {year: new Date().getFullYear()})}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <a href="/#" className="button button-blue button-top">
            <span>
                <i className="fas fa-arrow-up"/>
            </span>
        </a>
      </div>
    </footer>
  )
}

export default Footer;