import {useTranslation} from "react-i18next";

const FaqSection = () => {
  const {t} = useTranslation();

  return (
    <div className="faq-section" id="faqs">
      <div className="faq-section-wrapper" id="myFAQsectionWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="heading">
                <h1 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-67">
                  {t("Header::title::Ukraine")}
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2 col-10 offset-1">
              <div className="faq-section-content">
                <div className="accordion accordion-1" id="accordionFAQ">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOne"
                        aria-expanded="true"
                        aria-controls="faqOne"
                      >
                        <h4 className="c-dark">
                          {t("faq_ukraine::who_can_apply_title")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="faqHeadingOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::who_can_apply_01")}<br/><br/>
                          {t("faq_ukraine::who_can_apply_02")}<br/><br/>
                          {t("faq_ukraine::who_can_apply_03")}<br/><br/>
                          {t("faq_ukraine::who_can_apply_04")}<br/><br/>
                          {t("faq_ukraine::who_can_apply_05")}<br/><br/>
                          <p>
                            <h3><a href="https://redcross.org.ua/" target="_blank" rel="opener noreferrer">
                              https://redcross.org.ua/
                            </a></h3>
                          </p>
                          <p>
                            <h3><a href="https://aid.edopomoga.gov.ua/" target="_blank" rel="opener noreferrer">
                              https://aid.edopomoga.gov.ua/
                            </a></h3>
                          </p>
                        </p>
                        <br/><br/><br/><br/><br/><br/>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        <h4 className="c-dark">
                          {t("faq_ukraine::who_is_eligible_title")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faq2"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeading2"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::who_is_eligible_01")}<br/><br/>
                          {t("faq_ukraine::who_is_eligible_02")}<br/><br/>
                          {t("faq_ukraine::who_is_eligible_03")}<br/><br/>

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading3">
                      <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                              data-bs-target="#faq3" aria-controls="faq3">
                        <h4 className="c-dark">{t("faq_ukraine::how_much_assistance_title")}</h4>
                      </button>
                    </h2>
                    <div id="faq3" aria-labelledby="faqHeading3" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::how_much_assistance_01")}<br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading4">
                      <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                              data-bs-target="#faq4" aria-controls="faq4">
                        <h4 className="c-dark">{t("faq_ukraine::how_long_title")}</h4>
                      </button>
                    </h2>
                    <div id="faq4" aria-labelledby="faqHeading4" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::how_long_01")}<br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading5">
                      <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                              data-bs-target="#faq5" aria-controls="faq5">
                        <h4 className="c-dark">{t("faq_ukraine::how_to_receive_title")}</h4>
                      </button>
                    </h2>
                    <div id="faq5" aria-labelledby="faqHeading5" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::how_to_receive_01")}<br/><br/>
                          {t("faq_ukraine::how_to_receive_02")}<br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading6">
                      <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                              data-bs-target="#faq6" aria-controls="faq6">
                        <h4 className="c-dark">{t("faq_ukraine::no_sms_title")}</h4>
                      </button>
                    </h2>
                    <div id="faq6" aria-labelledby="faqHeading6" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::no_sms_01")}<br/><br/>
                          {t("faq_ukraine::no_sms_02")}<br/><br/>
                          {t("faq_ukraine::no_sms_03")} <a href="https://redcross.org.ua/" target="_blank" rel="opener noreferrer">https://redcross.org.ua/</a><br/><br/>
                          {t("faq_ukraine::no_sms_04")}<br/><br/>
                          0800 332 656<br/><br/>
                          {t("faq_ukraine::no_sms_05")}<br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeading7">
                      <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                              data-bs-target="#faq7" aria-controls="faq7">
                        <h4 className="c-dark">{t("faq_ukraine::how_can_i_spend_title")}</h4>
                      </button>
                    </h2>
                    <div id="faq7" aria-labelledby="faqHeading7" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                      <div className="accordion-body">
                        <p>
                          {t("faq_ukraine::how_can_i_spend_01")}<br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqHeading8">
                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                            data-bs-target="#faq8" aria-controls="faq8">
                      <h4 className="c-dark">{t("faq_ukraine::what_documents_title")}</h4>
                    </button>
                  </h2>
                  <div id="faq8" aria-labelledby="faqHeading8" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      <p>
                        {t("faq_ukraine::what_documents_01")}<br/><br/>
                        <ul>
                          <li>{t("faq_ukraine::what_documents_02")}</li>
                          <li>{t("faq_ukraine::what_documents_03")}</li>
                          <li>{t("faq_ukraine::what_documents_04")}</li>
                          <li>{t("faq_ukraine::what_documents_05")}</li>
                          <li>{t("faq_ukraine::what_documents_06")}</li>
                          <li>{t("faq_ukraine::what_documents_07")}</li>
                        </ul><br/>
                        {t("faq_ukraine::what_documents_08")} <a href="https://aid.edopomoga.gov.ua/" target="_blank" rel="opener noreferrer">https://aid.edopomoga.gov.ua/</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqHeading9">
                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                            data-bs-target="#faq9" aria-controls="faq9">
                      <h4 className="c-dark">{t("faq_ukraine::how_i_receive_title")}</h4>
                    </button>
                  </h2>
                  <div id="faq9" aria-labelledby="faqHeading9" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                    <div className="accordion-body">
                      <p>
                        {t("faq_ukraine::how_i_receive_01")}<br/><br/>
                        <ul>
                          <li>{t("faq_ukraine::how_i_receive_02")}</li>
                          <li>{t("faq_ukraine::how_i_receive_03")}</li>
                        </ul><br/>
                        <a href="https://ukrainefinancialassistance.ifrc.org/assets/docs/IFRC-MG-Instructions.pdf">PDF</a>
                        <a href="https://ukrainefinancialassistance.ifrc.org/assets/docs/IFRC-MG-DigitalPickup.pdf">PDF</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqHeading10">
                  <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                          data-bs-target="#faq10" aria-controls="faq10">
                    <h4 className="c-dark">{t("faq_ukraine::not_received_title")}</h4>
                  </button>
                </h2>
                <div id="faq10" aria-labelledby="faqHeading10" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                  <div className="accordion-body">
                    <p>
                      {t("faq_ukraine::not_received_01")}<br/><br/>
                      {t("faq_ukraine::not_received_02")}<br/><br/>
                      {/*{t("faq_ukraine::not_received_03")}<br/><br/>*/}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqHeading11">
                  <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                          data-bs-target="#faq11" aria-controls="faq11">
                    <h4 className="c-dark">{t("faq_ukraine::lost_moneygram_code_title")}</h4>
                  </button>
                </h2>
                <div id="faq11" aria-labelledby="faqHeading11" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                  <div className="accordion-body">
                    <p>
                      {t("faq_ukraine::lost_moneygram_code_01")}<br/><br/>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqHeading12">
                  <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                          data-bs-target="#faq12" aria-controls="faq12">
                    <h4 className="c-dark">{t("faq_ukraine::why_no_more_title")}</h4>
                  </button>
                </h2>
                <div id="faq12" aria-labelledby="faqHeading12" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                  <div className="accordion-body">
                    <p>
                      {t("faq_ukraine::why_no_more_01")}<br/><br/>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="faqHeading13">
                  <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" aria-expanded="true"
                          data-bs-target="#faq13" aria-controls="faq13">
                    <h4 className="c-dark">{t("faq_ukraine::more_info_title")}</h4>
                  </button>
                </h2>
                <div id="faq13" aria-labelledby="faqHeading13" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                  <div className="accordion-body">
                    <p>
                      {t("faq_ukraine::more_info_01")}<br/><br/>
                      {t("faq_ukraine::more_info_02")}<br/><br/>
                      <a href="https://redcross.org.ua/" target="_blank" rel="opener noreferrer">https://redcross.org.ua/</a><br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p><br/><br/><br/></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
