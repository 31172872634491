// noinspection AllyPlainJsInspection
//Todo: Changed to UKR -> UA AND RUS -> RU NEEDS TO BE TESTED <----
//Todo: Added Navbar Key because of the translation file !!!!! 'in translation file it says you should use these "navbar words"'

const LANG = {
    UA: {
      text: 'Розпочати',
      lng: 'ua',
      navbar:'Українська',
    },
    RU: {
      text: 'Начать',
      lng: 'ru',
      navbar:'Русский',
    },
    EN: {
      text: 'English',
      lng: 'en',
      navbar:'English',
    },
    // HU: {
    //   text: 'Magyar',
    //   lng: 'hu',
    //   navbar:'Magyar',
    // },
  };
  
  export default LANG;
  