import {useTranslation} from "react-i18next";

const FeatureSectionF = () => {
  const {t, i18n} = useTranslation();

  const myLng = i18n.language.substring(0,2);


  return (

    <div className="feature-section" id="about">
      <div className="feature-section-wrapper" id="mySelectedFeatureSectionWrapper">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-heading">
                <h1
                  className="t-center m-horz-auto c-dark heading-width-res-full heading-width-67"> {t('Apply::prepare')}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gx-5 feature-section-content-row">

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-user c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::focalPointChoosenew')}</h3>
                  <p>{t('Apply::focalPoint::discuss::Slovakia')}</p>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-id-card c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::preparedocs')}</h3>
                  <p>{t('Apply::prepDocs::sub::Slovakia')}</p>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-arrow-alt-circle-down c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::appnew')}</h3>
                  <p>{t('Apply::downloadApp::sub::Slovakia')}</p>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-id-badge c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::createAccount')}</h3>
                  <p>{t('Apply::createAccountSub')}</p>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-users c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::addFam::title::Slovakia')}</h3>
                  <p>{t('Apply::addFam::sub::Slovakia')}</p>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <i className="fa fa-list-ul c-blue icon"/>
                  <h3 className="c-dark">{t('Apply::applyAssistance::title::Slovakia')}</h3>
                  <p>{t('Apply::applyAssistance::sub::Slovakia')}</p>
                </div>
              </div>
              
              {/* <h3 style={{margin:25,paddingTop:25}}>{t("Apply::focalPointDiscuss2")}</h3> */}
              <p style={{paddingTop:10}}>{t("Apply::documentsFocalPoint")}</p>
              <ul style={{fontSize:15,marginLeft:20}}>
                <li>{t("Apply::documentsFocalPoint1")}</li>
                <li>{t("Apply::documentsFocalPoint2")}</li>
                <li>{t("Apply::documentsFocalPoint3")}</li>
              </ul>
            </div>

          <div className="row">
            <div className="button-group button-group-center">
              <div>
                <a href="https://apps.apple.com/app/підтримка/id1619005116">
                  <img className="p-2" src={`../assets/images/app-store-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                  <img className="p-2" src={`../assets/images/google-play-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
            </div>
          </div>

          <br/><br/>

          {/* <div className="row">
            <div className="button-group button-group-center">
              <div>
                <a href="https://www.youtube.com/watch?v=vUHiMr6AoCU">
                  <img className="p-2" src={`../assets/images/app-store-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/watch?v=-3xOPHawaZU">
                  <img className="p-2" src={`../assets/images/google-play-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
            </div>
          </div> */}

          {/* <p align="center">
            <iframe height="600" src="https://www.youtube.com/watch?v=-3xOPHawaZU"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe><br/>
          </p> */}

          <br/><br/><br/>

          <h3>
            {t('Apply::videos::intro')}<br/><br/>
          </h3>

          <h4>
            {t('Apply::videos::header1')}
          </h4>

          <p align="center">
            <iframe width="100%" height="600" src="https://www.youtube.com/embed/vUHiMr6AoCU"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </p>
          
       
          
        </div>
      </div>
    </div>
  )
}

export default FeatureSectionF;