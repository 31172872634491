import {useRef} from "react";
import {useTranslation} from "react-i18next";

const Navigation = () => {

  const {i18n} = useTranslation();

  function changeLanguage(language) {
    i18n.changeLanguage(language).then();
  }

  const buttonRef = useRef();
  const pageLng = i18n.language;

  return (
    <div className="navigation scrolled" ref={buttonRef}>
      <div className="navigation-wrapper">
        <div className="container-fluid">
          <div className="navigation-inner">
            <div className="navigation-logo">
              <a href="/">
                <img src="../../assets/images/ukrainian-red-cross.png" alt="ukrainian-red-cross"/>
              </a>
              <a href="/">
                <img src="../../assets/images/ifrc-logo.png" alt="ifrc"/>
              </a>
            </div>
            <div className="navigation-menu">
              <div className="mobile-header">
                <div className="logo">
                  <a href="/">
                    <img src="../../assets/images/ukrainian-red-cross.png" alt="ukrainian-red-cross"/>
                    <img src="../../assets/images/ifrc-logo.png" alt="ifrc"/>
                  </a>
                </div>
                <ul>
                  <li className="close-button" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <i className="fas fa-times"></i>
                  </li>
                </ul>
              </div>
              <ul className="parent" id="mySelectedNavbar" style={{paddingRight: `10px`}}>
                <li>
                  <a href="/ukrainian-red-cross#" className="scroll-button link-underline link-underline-1"
                     onClick={
                       () => {
                         changeLanguage('ua');
                         buttonRef.current.classList.value = "navigation scrolled responsive";
                       }
                     }>
                    <span className={pageLng.toUpperCase() === 'UA' ? "active" : "notActive"}>Українська</span>
                  </a>
                </li>
                <li>
                  <a href="/ukrainian-red-cross#" className="scroll-button link-underline link-underline-1"
                     onClick={
                       () => {
                         changeLanguage('en');
                         buttonRef.current.classList.value = "navigation scrolled responsive";
                       }
                     }>
                      <span className={pageLng.toUpperCase() === 'EN' ? "active" : "notActive"}>English</span>
                  </a>
                </li>
              </ul>
              <div className="social">
                <h5>Follow</h5>
                <ul>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-facebook-f"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-twitter"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-linkedin-in"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-youtube"></i>
                                        </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navigation-bar" onClick={() => {
              buttonRef.current.classList.value = "navigation scrolled responsive shown";
            }}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;