const PreLoader = () => {
  return (
    <div className="preloader">
      <div className='sk-folding-cube'>
        <img src="../../assets/images/montenegro-red-cross.logo.png" alt=""/>
        <img src="../../assets/images/ifrc-logo.png" alt=""/>
      </div>
    </div>
  )
}
export default PreLoader