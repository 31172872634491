import {useTranslation} from "react-i18next";

const FeatureStartTwo = () => {
  const {t} = useTranslation();

  return (
    <div className="features features-padding-2 position-relative" id="requirements">
      <div className="features-wrapper" id="mySelectedFeaturesWrapper2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2"
                id="requirements">
              <div className="features-content">
                <h2 className="c-dark lt-60"> {t('NeedToApply::whatAccount')}</h2>
                <p>{t('NeedToApply::focalPoint2')}
                </p>

                <div className="accordion" id="accordionFeature">

                  <div className="accordion-item-wrapper">
                    <i className="fa fa-mobile icon-small c-blue"/>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                aria-expanded="true" aria-controls="collapseFour">
                          <h4 className="c-dark">{t('NeedToApply::Smartphone')}</h4>
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse show"
                          aria-labelledby="headingFour" data-bs-parent="#accordionFeature">
                        <div className="accordion-body">
                          <p>{t('NeedToApply::smartPhone::General')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item-wrapper">
                    <i className="fa fa-id-card icon-small c-blue"/>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne">
                          <h4 className="c-dark">{t('NeedToApply::documents::General')} </h4>
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse"
                          aria-labelledby="headingOne" data-bs-parent="#accordionFeature">
                        <div className="accordion-body">
                          <p>
                            {t('NeedToApply::documents::Sub')}<br/>
                            {t('NeedToApply::documents::intPass')}<br/>
                            {t('NeedToApply::documents::natIdCard')} <br/>
                            {/* {t('NeedToApply::documents::tempProtection')} <br/> */}
                            {t('NeedToApply::documents::oldBookPass')} <br/>
                            {t('NeedToApply::documents::birthCertRomania')}<br/>
                            {t('NeedToApply::documents::foreignPassGeneral')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item-wrapper">
                    <i className="fa fa-comment icon-small c-blue"/>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                aria-expanded="true" aria-controls="collapseTwo">
                          <h4 className="c-dark">{t('NeedToApply::provideContact')}
                          </h4>
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse"
                          aria-labelledby="headingTwo" data-bs-parent="#accordionFeature">
                        <div className="accordion-body">
                          <p>{t('NeedToApply::provideContactSub')} <br/>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                
                <p><b>{t('NeedToApply::warningRomania')}</b>
                </p>
                <p><b>{t('NeedToApply::importantInfo')}</b>
                </p>

              </div>
            </div>

            <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
              <div className="pattern-image pattern-image-reverse">
                <div className="background-layout">
                  <div className="box-1 box-image"
                      style={{backgroundImage: `url('../assets/images/app-testing-2.JPG')`}}>
                    <div className="box-1-1 bg-red"/>
                    <div className="box-1-2 bg-red"/>
                  </div>
                  <div className="box-2 bg-blue">
                    <div className="box-2-1 bg-yellow"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="background-layout">
        <div className="box-1 bg-light-grey"/>
      </div>
    </div>
  )
}

export default FeatureStartTwo;