import { useTranslation } from "react-i18next";

import mgLoc from "../../assets/images/moneygram-location.png";

const FaqSection = () => {
  const { t, i18n } = useTranslation();
  const myLng = i18n.language.substring(0, 2);
  const country = "Slovakia"; 
  return (
    <div className="faq-section" id="faqs">
      <div className="faq-section-wrapper" id="myFAQsectionWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="heading">
                <h1 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-67">
                  {t("faqGeneral::header")}
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2 col-10 offset-1">
              <div className="faq-section-content">
                <div className="accordion accordion-1" id="accordionFAQ">


                  <div className="row">
                    <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                      <div className="heading">
                        <h2 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-100">
                          {t("faqGeneral::headerRCSlovakia")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneOne"
                        aria-expanded="true"
                        aria-controls="faqOneOne"
                      >
                        <h4 className="c-dark">
                        {t("Eligibility::who")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t('Eligibility::Description::General')}<br/><br/>
                        {t('Eligibility::Criteria::Slovakia::tempSk')}<br/><br/>
                        {t('Eligibility::Criteria::Slovakia::currentLocation')}<br/><br/>
                        {t('Eligibility::Criteria::Slovakia::age')} <br/><br/>
                        {t('Eligibility::Criteria::Slovakia::insurance')} <br/><br/>
                        {t('Eligibility::Criteria::Slovakia::diagnosis')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag1')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag2')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag3')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag4')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag5')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag6')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag7')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag8')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag9')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag10')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag11')} <br/>
                        {t('Eligibility::Criteria::Slovakia::diag12')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneTwo"
                        aria-expanded="true"
                        aria-controls="faqOneTwo"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::whyHealthAssistanceQ::Slovakia")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::whyHealthAssistanceA::Slovakia")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneTwoPL">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneTwoPL"
                        aria-expanded="true"
                        aria-controls="faqOneTwoPL"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::chronicDiseasesQ::Slovakia")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneTwoPL"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneTwoPL"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t('faqGeneral::chronicDiseasesA::Slovakia')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneFour"
                        aria-expanded="true"
                        aria-controls="faqOneFour"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::transferValueQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneFour"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t("faqGeneral::transferValueA::Slovakia")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneFive"
                        aria-expanded="true"
                        aria-controls="faqOneFive"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::bot::durationQ"
                          )}{" "}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneFive"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::durationA::Slovakia"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneSix"
                        aria-expanded="true"
                        aria-controls="faqOneSix"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::pickUpQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneSix"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t("faqGeneral::pickUpA")}
                        </p>
                      </div>
                    </div>
                  </div> */}
{/* 
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOneSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOneSeven"
                        aria-expanded="true"
                        aria-controls="faqOneSeven"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::contactRCQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOneSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingOneSeven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {<li>{t("Header::Safeguarding::Poland")}</li>}<br/>
                        {<li>{t("faqGeneral::contactRCA::Poland")}</li>}
                        <a href="https://pck.pl/kontakt/">https://pck.pl/kontakt/</a>
                        </p>
                      </div>
                    </div>
                  </div> */}
                  
                  <br/><br/><br/><br/>

                  <div className="row">
                    <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                      <div className="heading">
                        <h2 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-100">
                          {t("faqGeneral::headerAppProcesSK")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoOne"
                        aria-expanded="true"
                        aria-controls="faqTwoOne"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::applyQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::applyAnew::explanation")}<br/>
                          <b>{t("faqGeneral::applyAnew::step1")}</b>
                          {t("faqGeneral::applyAnew::step1ex")}<br/>
                          <b>{t("faqGeneral::applyAnew::step2")}</b>
                          {t("faqGeneral::applyAnew::step2ex")}<br/>
                          <b>{t("faqGeneral::applyAnew::step3")}</b>
                          {t("faqGeneral::applyAnew::step3ex")}
                        </p>
                        <div className="row">
                          <div className="button-group button-group-center">
                            <div>
                              <a href="https://apps.apple.com/app/підтримка/id1619005116">
                                <img
                                  className="p-2"
                                  src={`../assets/images/app-store-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                            <div>
                              <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                                <img
                                  className="p-2"
                                  src={`../assets/images/google-play-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoTwo"
                        aria-expanded="true"
                        aria-controls="faqTwoTwo"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::accountEnoughQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t("faqGeneral::accountEnoughASK")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoThree"
                        aria-expanded="true"
                        aria-controls="faqTwoThree"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::noPhoneQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoThree"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::noPhoneA::Slovakia")}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoFour"
                        aria-expanded="true"
                        aria-controls="faqTwoFour"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::documentsNeededQ::General")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoFour"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::documentsNeededA::Slovakia"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoTen"
                        aria-expanded="true"
                        aria-controls="faqTwoTen"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::documentsQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoTen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::documentsAnew"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoFive"
                        aria-expanded="true"
                        aria-controls="faqTwoFive"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::permissionsQ::general"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoFive"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::permissionsA::Slovakia"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoSeven"
                        aria-expanded="true"
                        aria-controls="faqTwoSeven"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::reviewTimeQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoSeven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::reviewTimeA"
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoSix"
                        aria-expanded="true"
                        aria-controls="faqTwoSix"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bot::registrationConfirmationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoSix"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::registrationConfirmationAnew"
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoEight"
                        aria-expanded="true"
                        aria-controls="faqTwoEight"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::knowApprovedQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoEight"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::knowApprovedA")}<br/><br/>
                          {t("faqGeneral::knowApprovedASK")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoNine"
                        aria-expanded="true"
                        aria-controls="faqTwoNine"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::whenPaymentQ::general"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoNine"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::whenPaymentA::Slovakia")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwoEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwoEleven"
                        aria-expanded="true"
                        aria-controls="faqTwoEleven"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::dataQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwoEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwoEleven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::dataA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
          
                  <br/><br/><br/><br/>
                  
                  <div className="row">
                    <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                      <div className="heading">
                        <h2 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-100">
                          {t("faqGeneral::headerReceivingAssistance")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThreeOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThreeOne"
                        aria-expanded="true"
                        aria-controls="faqThreeOne"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::deliveryQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThreeOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThreeOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                          <p>
                            {t("faqGeneral::deliveryAnew::Slovakia")}<br/><br/>
                            {t("faqGeneral::deliveryAnew::General2")}<br/><br/>
                            {/* <b>{t("faqGeneral::deliveryAnew::MoneyGramCash1")}</b><br/>
                            {t("faqGeneral::deliveryAnew::MoneyGramCash2")}<br/>
                            {t("faqGeneral::deliveryAnew::MoneyGramCash3")}<br/>
                            {t("faqGeneral::deliveryAnew::MoneyGramCash4")}<br/>
                            <a href="https://www.moneygram.com/intl/en/en-locator">https://www.moneygram.com/intl/en/en-locator</a><br/>
                            <img src={mgLoc} alt="" /><br/><br/> */}
                            <b>{t("faqGeneral::deliveryAnew::IBANtransfer3")}</b><br/>
                            {t("faqGeneral::deliveryAnew::IBANtransfer2")}<br/><br/>
                            <b>{t("faqGeneral::deliveryAnew::MoneyGramCash5")}</b><br/>
                            {t("faqGeneral::deliveryAnew::MoneyGramDigital5")}<br/><br/>
                            <b>{t("faqGeneral::deliveryAnew::MoneyGramCash6")}</b><br/>
                            {t("faqGeneral::deliveryAnew::MoneyGramCash7")}<br/>
                            <a href="https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf">https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf</a><br/><br/>
                            
                            </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThreeTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThreeTwo"
                        aria-expanded="true"
                        aria-controls="faqThreeTwo"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::changePaymentMethodQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThreeTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThreeTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::changePaymentMethodA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThreeThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThreeThree"
                        aria-expanded="true"
                        aria-controls="faqThreeThree"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::stayInCountryQnew::Poland"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThreeThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThreeThree"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::stayInCountryAnew::Poland"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <br/><br/><br/><br/>
                  
                  <div className="row">
                    <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                      <div className="heading">
                        <h2 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-100">
                          {t("faqGeneral::headerContact")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingContactOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqContactOne"
                        aria-expanded="true"
                        aria-controls="faqContactOne"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::feedbackRCQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqContactOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingContactOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::feedbackRCA::General")}<br/><br/>
                          {t("faqGeneral::feedbackRCA::Slovakia::helpline")}<br/>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::langHelpline")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::calls")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::calls")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::texts")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::email")}</li><br/>
                          {t("faqGeneral::feedbackRCA::Slovakia::hsp")}<br/>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::langHSP")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::locations")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::infoHSP")}</li>
                          <li>{t("faqGeneral::feedbackRCA::Slovakia::visitCenter")}</li>
                          <a href="https://redcross.sk/kpk/">Kancelárie prvého kontaktu / Офіси першого контакту – Slovenský Červený kríž (redcross.sk)</a>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingContactTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqContactTwo"
                        aria-expanded="true"
                        aria-controls="faqContactTwo"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::reportConcernsQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqContactTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingContactTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::reportConcernsA::General1")}<br/><br/>
                          {t("faqGeneral::redCrossGeneralA::General")}<br/><br/>
                          <li>{t("Header::Safeguarding::Poland")}</li><br/>
                          <li>{t("Header::Safeguarding::General::integrityline")}</li><br/>
                          <li>{t("Header::Safeguarding::General::trustMember")}</li><br/>
                          {t("faqGeneral::reportConcernsA::General2")}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

                  {/* <br/><br/><br/><br/>
                  <div className="row">
                    <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                      <div className="heading">
                        <h2 className="lt-80 t-center m-horz-auto c-dark heading-width-res-full heading-width-100">
                          {t("faqGeneral::headerSplit")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqOne"
                        aria-expanded="true"
                        aria-controls="faqOne"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::eligibleQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="faqHeadingOne"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                        {t('Eligibility::Description::General')}<br/><br/>
                        {t('Eligibility::Criteria::Slovakia::dueConflict')}<br/><br/>
                        {t('Eligibility::Criteria::Slovakia::currentLocation')} <br/><br/>
                        {t('Eligibility::Criteria::Slovakia::category')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categorya')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryb')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryc')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryd')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categorye')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryf')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryg')} <br/>
                        {t('Eligibility::Criteria::Slovakia::categoryh')} <br/><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwo"
                        aria-expanded="true"
                        aria-controls="faqTwo"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::focalpointQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwo"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::focalpointA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThree"
                        aria-expanded="true"
                        aria-controls="faqThree"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::applyQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThree"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>{t("faqGeneral::applyA")}</p>
                        <div className="row">
                          <div className="button-group button-group-center">
                            <div>
                              <a href="https://apps.apple.com/app/підтримка/id1619005116">
                                <img
                                  className="p-2"
                                  src={`../assets/images/app-store-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                            <div>
                              <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                                <img
                                  className="p-2"
                                  src={`../assets/images/google-play-badge-${myLng}.png`}
                                  alt=""
                                  width="250"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFour"
                        aria-expanded="true"
                        aria-controls="faqFour"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::transferValueQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFour"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p> {t("faqGeneral::transferValueA::Slovakia")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSeven"
                        aria-expanded="true"
                        aria-controls="faqSeven"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::bot::durationQ"
                          )}{" "}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSeven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::durationA::Slovakia"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEight"
                        aria-expanded="true"
                        aria-controls="faqEight"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bot::registrationConfirmationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEight"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::bot::registrationConfirmationA"
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqNine"
                        aria-expanded="true"
                        aria-controls="faqNine"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t(
                            "faqGeneral::duplicateRegistrationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingNine"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::duplicateRegistrationA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/*el */}
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTen"
                        aria-expanded="true"
                        aria-controls="faqTen"
                      >
                        <h4 className="c-dark">
                          {" "}
                          {t("faqGeneral::noPhoneQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t("faqGeneral::noPhoneA::General")}<br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEleven"
                        aria-expanded="true"
                        aria-controls="faqEleven"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::documentsQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEleven"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::documentsA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwelve">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwelve"
                        aria-expanded="true"
                        aria-controls="faqTwelve"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::cashUseQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwelve"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::cashUseA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThirteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqThirteen"
                        aria-expanded="true"
                        aria-controls="faqThirteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::dataQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqThirteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThirteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                            {t("faqGeneral::dataA")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFourTeen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFourteen"
                        aria-expanded="true"
                        aria-controls="faqFourteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::viberDeletedQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFourteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFourTeen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body ">
                        <p>{t("faqGeneral::viberDeletedA1")}</p>
                        <p>{t("faqGeneral::viberDeletedA2")}</p>
                        <p>{t("faqGeneral::viberDeletedA3")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFifteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqFifteen"
                        aria-expanded="true"
                        aria-controls="faqFifteen"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::deliveryQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqFifteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFifteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                          <p>{t("faqGeneral::deliveryA")}</p>
                      </div>
                    </div>
                  </div> */}
                  {/* Where can I find my nearest MoneyGram agent location? */}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeen"
                        aria-expanded="true"
                        aria-controls="faqSevenTeen"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::mgLocationQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p> {t("faqGeneral::mgLocationA")}</p>
                        <img src={mgLoc} alt="" />
                      </div>
                    </div>
                  </div>
        
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeenX">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeenX"
                        aria-expanded="true"
                        aria-controls="faqSevenTeenX"
                      >
                        <h4 className="c-dark">
                       {t('faqGeneral::bot::digitalPickupQ')}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeenX"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeenX"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>{t('faqGeneral::bot::digitalPickupA1')}</p>
                        <p>{t('faqGeneral::bot::digitalPickupA2')} <a href="https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf">https://ukrainefinancialassistance.ifrc.org/assets/docs/DigitalPickupManualIFRC.pdf</a></p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingSevenTeenM">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqSevenTeenM"
                        aria-expanded="true"
                        aria-controls="faqSevenTeenM"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bot::digitalPickupErrorQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqSevenTeenM"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingSevenTeenM"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t("faqGeneral::bot::digitalPickupErrorA")}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/*
I lost my MoneyGram code
 */}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingighteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqEigteeighteen"
                        aria-expanded="true"
                        aria-controls="faqEigteeighteen"
                      >
                        <h4 className="c-dark">
                          {t("faqGeneral::codeLostQ")}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqEigteeighteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingEigteeighteen"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {t(
                            "faqGeneral::codeLostA"
                          )}
                        </p> 
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwenty">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwenty"
                        aria-expanded="true"
                        aria-controls="faqTwenty"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::euroQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwenty"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwenty"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::euroA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/*
I do not have a bank account and would like to sign up for one to receive a bank transfer.
 */}
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwentyone">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwentyone"
                        aria-expanded="true"
                        aria-controls="faqTwentyone"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::bankAccountQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwentyone"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwentyone"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                      <p>
                          {" "}
                          {t(
                            "faqGeneral::bankAccountQ"
                          )}
                        </p>
                        <p>{t("faqGeneral::bankAccountA2")}</p>
                        <p><a href="https://youtu.be/qECYzG0RpaU">https://youtu.be/qECYzG0RpaU .</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwentySix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqTwentySix"
                        aria-expanded="true"
                        aria-controls="faqTwentySix"
                      >
                        <h4 className="c-dark">
                          {t(
                            "faqGeneral::stayInCountryQ"
                          )}
                        </h4>
                      </button>
                    </h2>
                    <div
                      id="faqTwentySix"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwentySix"
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">
                        <p>
                          {" "}
                          {t(
                            "faqGeneral::stayInCountryA"
                          )}
                        </p>
                      </div>
                    </div>
                  </div> */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
