import React from 'react';
import "./i18n";
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import RomanianRedCross from "./RomanianRedCross";
import PolishRedCross from "./PolishRedCross";
import SlovakRedCross from "./SlovakRedCross";
import UkrainianRedCross from "./UkrainianRedCross";
import HungarianRedCross from "./HungarianRedCross";
import "./assets/css/overflow.css";
import BulgarianRedCross from "./BulgarianRedCross";
import MoldovaRedCross from "./MoldovaRedCross";
import LithuanianRedCross from "./LithuanianRedCross";
import MontenegrinRedCross from './MontenegrinRedCross';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/romanian-red-cross" element={<RomanianRedCross />} />
        <Route path="/polish-red-cross" element={<PolishRedCross />} />
        <Route path="/slovak-red-cross" element={<SlovakRedCross />} />
        <Route path="/ukrainian-red-cross" element={<UkrainianRedCross />} />
        <Route path="/hungarian-red-cross" element={<HungarianRedCross />} />
        <Route path="/bulgarian-red-cross" element={<BulgarianRedCross />} />
        <Route path="/moldova-red-cross" element={<MoldovaRedCross />} />
        <Route path="/lithuanian-red-cross" element={<LithuanianRedCross />} />
        <Route path="/montenegro-red-cross" element={<MontenegrinRedCross />} />
        <Route path="*" element={<App />}/>
      </Routes>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
