import {useRef} from "react";
import {useTranslation} from "react-i18next";
import LANGME from "../../LangMonte";

const Navigation = () => {
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  function changeLanguage(language) {
      i18n.changeLanguage(language).then();
  }

  const buttonRef = useRef();
  const pageLng = i18n.language;

  return (
    <div className="navigation scrolled" ref={buttonRef}>
      <div className="navigation-wrapper">
        <div className="container-fluid">
          <div className="navigation-inner">
            <div className="navigation-logo">
              <a href="/">
              <img src="../../assets/images/montenegro-red-cross.logo.png" width="75" alt="montenegro-red-cross"/>
              </a>
              <a href="/">
                <img src="../../assets/images/ifrc-logo.png" alt="ifrc"/>
              </a>
            </div>
            <div className="navigation-menu">
              <div className="mobile-header">
                <div className="logo">
                  <a href="/">
                    <img src="../../assets/images/montenegro-red-cross.logo.png" alt="montenegro-red-cross"/>
                    <img src="../../assets/images/ifrc-logo.png" alt="ifrc"/>
                  </a>
                </div>
                <ul>
                  <li className="close-button" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <i className="fas fa-times"></i>
                  </li>
                </ul>
              </div>
              <ul className="parent" id="mySelectedNavbar" style={{paddingRight: `10px`}}>
                <li>
                  <a href="#about" className="scroll-button link-underline link-underline-1"
                     id="NavbarGettingStarted" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <span>{t('Navigation::geting_Started')} </span>
                  </a>
                </li>
                <li>
                  <a href="#eligibility" className="scroll-button link-underline link-underline-1"
                     id="NavbarEligibility" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <span> {t('Navigation::Eligibility')}</span>
                  </a>
                </li>
                <li>
                  <a href="#requirements" className="scroll-button link-underline link-underline-1"
                     id="NavbarRequirements" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <span>{t('Navigation::Requirements')}</span>
                  </a>
                </li>
                <li>
                  <a href="#faqs" className="scroll-button link-underline link-underline-1"
                     id="NavbarFAQ" onClick={() => {
                    buttonRef.current.classList.value = "navigation scrolled responsive";
                  }}>
                    <span>{t('Navigation::FAQs')}</span>
                  </a>
                </li>
                <li> | </li>
                <li>
                  <ul className="parent" id="mySelectedNavbar" style={{paddingRight: `10px`}}>
                      {Object.keys(LANGME).map((item) => {
                          return (
                            <li key={item}>
                                <a href="#" className="scroll-button link-underline link-underline-1" id={item}
                                    onClick={
                                        () => {
                                            changeLanguage(LANGME[item].lng);
                                            buttonRef.current.classList.value = "navigation scrolled responsive";
                                        }
                                    }>
                                    <span
                                      className={pageLng.toUpperCase() === item ? "active" : "notActive"}>{LANGME[item].navbar}</span>
                                </a>
                            </li>
                          )
                      })}
                  </ul>
                </li>
              </ul>
              <div className="social">
                <h5>Follow</h5>
                <ul>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-facebook-f"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-twitter"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-linkedin-in"></i>
                                        </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="c-dark button button-blue">
                                        <span>
                                            <i className="fab fa-youtube"></i>
                                        </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navigation-bar" onClick={() => {
              buttonRef.current.classList.value = "navigation scrolled responsive shown";
            }}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;