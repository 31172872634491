import {useTranslation} from "react-i18next";

const FeatureStart = () => {
  const {t} = useTranslation();

  return (
    <div className="features features-padding-1" id="eligibility">
      <div className="features-wrapper" id="mySelectedFeatures">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="background-layout">
                    <div className="box-1-2 bg-red"/>
                  </div>
                    <div className="box-2-1 bg-blue"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="features-content">
                <p>
                </p>
        </div>
      </div>
    </div>
  )
}

export default FeatureStart;