// Collision BugFix

const FaqSection = () => {
  return (
    <div className="faq-section" id="faqs">
      <div className="faq-section-wrapper" id="myFAQsectionWrapper">
        <div className="container"></div>
      </div>
    </div>
  );
};

export default FaqSection;
