//useEffect
import { useEffect } from "react";


// Components
import PreLoader from "./components/Landing/PreLoader";
import Navigation from "./components/Landing/Navigation";
import Header from "./components/Landing/Header";
import Footer from "./components/Landing/Footer";
import FaqSection from "./components/Landing/FaqSection";

// i18n
import { useTranslation } from "react-i18next";

import "./assets/css/style.css";
import "./assets/css/forNavbar.css";
import CtaSection from "./components/Landing/CtaSection";

const App = () => {
  const { i18n } = useTranslation();
  i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
    document.dir = i18n.dir(lng);
  });

useEffect(() => {
  if(i18n.language.substring(0,2) !== "en" && i18n.language.substring(0,2) !== "ru" && i18n.language.substring(0,2) !== "ua"){

    document.documentElement.setAttribute("lang", "en");
    document.dir = i18n.dir("en");
    localStorage.setItem("i18nextLng","en");
  }
},[i18n])

  return (
    <div>
      <PreLoader />
      <Navigation />
      <Header />
      <FaqSection />
      <CtaSection />
      <Footer />
    </div>
  );
};

export default App;
