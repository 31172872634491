import {useTranslation} from "react-i18next";

const FeatureSectionF = () => {
  const {t, i18n} = useTranslation();

  const myLng = i18n.language.substring(0,2);


  return (

    <div className="feature-section" id="about">
      <div className="feature-section-wrapper" id="mySelectedFeatureSectionWrapper">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-heading">
                <h1
                  className="t-center m-horz-auto c-dark heading-width-res-full heading-width-67"> {t('Apply::prepare')}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gx-5 feature-section-content-row">

          <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-user c-blue icon"/>
                <h3 className="c-dark">{t('Apply::focalPointChoosenew')}</h3>
                <p>{t('Apply::focalPointDiscuss')}</p>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-id-card c-blue icon"/>
                <h3 className="c-dark">{t('Apply::preparedocs')}</h3>
                <p>{t('Apply::documentMembers2')}</p>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-arrow-alt-circle-down c-blue icon"/>
                <h3 className="c-dark">{t('Apply::appnew')}</h3>
                <p>{t('Apply::appSub')}</p>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-id-badge c-blue icon"/>
                <h3 className="c-dark">{t('Apply::createAccount')}</h3>
                <p>{t('Apply::createAccountSub')}</p>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-users c-blue icon"/>
                <h3 className="c-dark">{t('Apply::addFamilyRomania')}</h3>
                <p>{t('Apply::addFamilySubRomania')}</p>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="feature-section-content">
                <i className="fa fa-list-ul c-blue icon"/>
                <h3 className="c-dark">{t('Apply::applyProgram')}</h3>
                <p>{t('Apply::applyProgramSub')}</p>
              </div>
            </div>

            <h3 style={{margin:25,paddingTop:25}}>{t("Apply::focalPointDiscuss2")}</h3>
            <p style={{paddingTop:10}}>{t("Apply::documentsFocalPointRO")}</p>
            <ul style={{fontSize:15,marginLeft:20}}>
              <li>{t("Apply::documentsFocalPoint1")}</li>
              <li>{t("Apply::documentsFocalPoint2")}</li>
              <li>{t("Apply::documentsFocalPoint3")}</li>
              {/* <li>{t("Apply::documentsFocalPoint4")}</li> */}
              <li>{t("Apply::documentsFocalPoint6")}</li>
            </ul>
            {/* <p style={{paddingTop:10}}>{t("Apply::correctPhoto")}</p> */}

            {/* <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div align="center">
                  <h3><br/><br/><br/>{t('Apply::videoguide')}<br/><br/></h3>
                </div>
                <p align="center">
                  <iframe width="100%" height="600" src="https://www.youtube.com/embed/r41l2pquG-s"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div align="left">
                  <h3 style={{fontWeight:350}}>{t('Apply::contact::General')}</h3>
                </div>
                <ul>
                  <li><h4>{t('Apply::contact::Romania::call')}</h4></li>
                  <li><h4>{t('Apply::contact::Romania::email')} <a href="mailto:support.rrc@crucearosie.ro">support.rrc@crucearosie.ro</a></h4></li>
                  <li><h4>{t('Apply::contact::Romania::telegram')}</h4></li>
                  <li><h4>{t('Apply::contact::Romania::viber')}</h4></li>
                  <li><h4>{t('Apply::contact::Romania::visit')} <a href="https://crucearosie.ro/contact-filiale">https://crucearosie.ro/contact-filiale</a></h4></li>
                </ul>
              </div>
            </div>
          </div> */}
            
          </div>
          <div className="row">
            <div className="button-group button-group-center">
              <div>
                <a href="https://apps.apple.com/app/підтримка/id1619005116">
                  <img className="p-2" src={`../assets/images/app-store-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                  <img className="p-2" src={`../assets/images/google-play-badge-${myLng}.png`} alt="" width="360"/>
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default FeatureSectionF;