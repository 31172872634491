// Components
import PreLoader from "./components/Polish-Red-Cross/PreLoader";
import Navigation from "./components/Polish-Red-Cross/Navigation";
import Header from "./components/Polish-Red-Cross/Header";
import FeatureSectionF from "./components/Polish-Red-Cross/PrepApply";
import FeatureStart from "./components/Polish-Red-Cross/Eligibility";
import FeatureStartTwo from "./components/Polish-Red-Cross/NeedToApply";
import FaqSection from "./components/Polish-Red-Cross/FaqSection";
import CtaSection from "./components/Polish-Red-Cross/CtaSection";
import Footer from "./components/Polish-Red-Cross/Footer";

import "./assets/css/style.css";
import "./assets/css/forNavbar.css";

const PolishRedCross = () => {
  return(
    <div>
      <PreLoader />
      <Navigation />
      <Header />
      <FeatureSectionF />
      <FeatureStart />
      <FeatureStartTwo />
      <FaqSection />
      <CtaSection/>
      <Footer />
    </div>
  );
}

export default PolishRedCross;