import {useTranslation} from "react-i18next";

const CtaSection = () => {
    const {t, i18n} = useTranslation();

    const myLng = i18n.language.substring(0,2);

    return (

      <div className="cta-section position-relative">
          <div className="cta-section-wrapper  bg-red">
              <div className="container">
                  <div className="row d-lg-flex align-items-lg-center">
                      <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                          <div className="cta-section-content" id="myCTAsection">
                              <h2 className="c-white">
                                  {t('Header::title::Bulgaria')}
                              </h2>
                              <div className="button-group button-group-center">
                                  <div>
                                      <a href="https://apps.apple.com/app/підтримка/id1619005116">
                                          <img className="p-2" src={`../assets/images/app-store-badge-${myLng}.png`} alt="" width="60%"/>
                                      </a>
                                  </div>
                                  <div>
                                      <a href="https://play.google.com/store/apps/details?id=org.ifrc.self_registration">
                                          <img className="p-2" src={`../assets/images/google-play-badge-${myLng}.png`} alt="" width="60%"/>
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                          <div className="cta-section-image">
                              <img src="../../assets/images/bulgaria-red-cross-photo.jpg" alt="phone"/>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
    )
}

export default CtaSection;