import useWindowDimensions from "../../useWindowDimensions";
import {useTranslation} from "react-i18next";

const Header = () => {
  const {i18n} = useTranslation();
  const myLng = i18n.language.substring(0,2);
  const safeguardingImageUrl = "../../assets/images/romania-safeguarding-" + myLng + ".png"

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="header-content t-center" id="MySelectedHeaderContent">
                <p>
                  <img src={safeguardingImageUrl} alt="safeguarding-message" width="100%"/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;