import {useRef} from "react";
import {useTranslation} from "react-i18next";
import LANGHU from "../../LangHu";

const Navigation = () => {
    const {i18n} = useTranslation();

    function changeLanguage(language) {
        i18n.changeLanguage(language).then();
    }

    const buttonRef = useRef();
    const pageLng = i18n.language;

    return (
      <div className="navigation scrolled" ref={buttonRef}>
          <div className="navigation-wrapper">
              <div className="container-fluid">
                  <div className="navigation-inner">
                      <div className="navigation-logo">
                          <a href="/">
                              <img src="../../assets/images/ifrc-logo.png" alt="ifrc-logo"/>
                          </a>
                      </div>
                      <div className="navigation-menu">
                          <div className="mobile-header">
                              <div className="logo">
                                  <a href="/">
                                      <img src="../../assets/images/ifrc-logo.png" alt="ifrc-logo"/>
                                  </a>
                              </div>
                              <ul>
                                  <li className="close-button" onClick={() => {
                                      buttonRef.current.classList.value = "navigation scrolled responsive";
                                  }}>
                                      <i className="fas fa-times"></i>
                                  </li>
                              </ul>
                          </div>
                          <ul className="parent" id="mySelectedNavbar" style={{paddingRight: `10px`}}>
                              {Object.keys(LANGHU).map((item) => {
                                  return (
                                    <li key={item}>
                                        <a href="/#" className="scroll-button link-underline link-underline-1" id={item}
                                           onClick={
                                               () => {
                                                   changeLanguage(LANGHU[item].lng);
                                                   buttonRef.current.classList.value = "navigation scrolled responsive";
                                               }
                                           }>
                                            <span
                                              className={pageLng.toUpperCase() === item ? "active" : "notActive"}>{LANGHU[item].navbar}</span>
                                        </a>
                                    </li>
                                  )
                              })}
                          </ul>
                      </div>
                      <div className="navigation-bar" onClick={() => {
                          buttonRef.current.classList.value = "navigation scrolled responsive shown";
                      }}>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Navigation;