import useWindowDimensions from "../../useWindowDimensions";
import {useTranslation} from "react-i18next";

const Header = (Props) => {
  const {width} = useWindowDimensions();

  const {t, i18n} = useTranslation();

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="header-content t-center" id="MySelectedHeaderContent">
                {i18n.language === "en" && width > 922 ?
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                      id="theSlogan"> {t('Header::title::General')} </h1> :
                  <h1 className="m-horz-auto heading-width-res-full heading-width-83" style={{lineHeight: `8rem`}}
                      id="theSlogan"> {t('Header::title::General')} </h1>}
                <p className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                  {t('Header::subtitle::General')}</p>
                <h2 className="paragraph-big m-horz-auto heading-width-res-full heading-width-65">
                  {t('Header::subtitle::choosecountry')}</h2> <br/><br/>

                <div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-3 t-center">
                      <a href="/polish-red-cross">
                        <img src="../../assets/images/pol-logo.png" alt="polish-red-cross" width="100%" />
                      </a>
                      <a href="/polish-red-cross">
                        <h3>{t('Landing::Poland')}</h3><br/>
                      </a>
                    </div>
                    <div className="col-3 t-center">
                      <a href="/romanian-red-cross">
                        <img src="../../assets/images/rom-logo.svg" alt="romanian-red-cross" width="100%"/>
                      </a>
      
                      <a href="/romanian-red-cross">
                        <h3>{t('Landing::Romania')}</h3>
                      </a>
                    </div>
                    <div className="col-3 t-center">
                      <a href="/slovak-red-cross">
                        <img src="../../assets/images/slovak-red-cross.png" alt="slovak-red-cross" width="100%"/>
                      </a>
      
                      <a href="/slovak-red-cross">
                        <h3>{t('Landing::Slovakia')}</h3>
                      </a>
                    </div>
                  </div>
                  {/* Dividing our router board */}
                  <div className="row d-flex justify-content-center">
                    <div className="col-3 t-center">
                      <a href="/ukrainian-red-cross">
                        <img src="../../assets/images/ukrainian-red-cross.png" alt="ukrainian-red-cross" width="100%"/>
                      </a>
      
                      <a href="/ukrainian-red-cross">
                        <h3>{t('Landing::Ukraine')}</h3><br/>
                      </a>
                    </div>
                    <div className="col-3 t-center">
                      <a href="/hungarian-red-cross">
                        <img src="../../assets/images/Hungarian-Red-Cross-Logo.png" alt="hungarian-red-cross" width="100%"/>
                      </a>
      
                      <a href="/hungarian-red-cross">
                        <h3>{t("Landing::Hungary")}</h3>
                      </a>
                    </div>
                    <div className="col-3 t-center">
                      <a href="/bulgarian-red-cross">
                        <img src="../../assets/images/bulgaria-red-cross-logo.png" alt="bulgarian-red-cross" width="100%"/>
                      </a>
      
                      <a href="/bulgarian-red-cross">
                        <h3>{t('Landing::Bulgaria')}</h3>
                      </a>
                    </div>
                  </div>
                  {/* Dividing our router board */}
                  <div className="row d-flex justify-content-center">

                    <div className="col-3 t-center">
                      <a href="/moldova-red-cross">
                        <img src="../../assets/images/moldova-red-cross-logo.png" alt="moldova-red-cross" width="100%"/>
                      </a>
      
                      <a href="/moldova-red-cross">
                        <h3>{t("Landing::Moldova")}</h3><br/><br/>
                      </a>
                      
                    </div>

                    <div className="col-3 t-center">
                    <a href="/lithuanian-red-cross">
                        <img src="../../assets/images/lithuania-red-cross-logo.svg" alt="lithuania-red-cross" width="70%"/>
                      </a>
      
                      <a href="/lithuanian-red-cross">
                        <h3 style={{marginTop:"10px"}} >{t("Landing::Lithuania")}</h3><br/><br/>
                      </a>
                    </div>

                    <div className="col-3 t-center">
                    <a href="/montenegro-red-cross">
                        <img src="../../assets/images/montenegro-red-cross.logo.png" alt="montenegro-red-cross" width="100%"/>
                      </a>

                      <a href="/montenegro-red-cross">
                        <h3>{t("Landing::Montenegro")}</h3><br/><br/>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="header-image m-horz-auto position-relative">
                  <img src="../../assets/images/header-phone-4.png" alt="phone"/>
                  <div className="background-layout">
                    <div className="box-1 bg-black">
                      <div className="box-2 bg-blue-rgba-8">
                        <div className="box-3 bg-grey">
                          <div className="box-4 bg-grey"></div>
                        </div>
                      </div>
                    </div>
                    <div className="box-5 bg-red-rgba-8">
                      <div className="box-6 bg-grey">
                        <div className="box-7 bg-blue"></div>
                        <div className="box-8 bg-grey"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Header;