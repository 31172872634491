
const LANGME = {
    UA: {
      text: 'Розпочати',
      lng: 'ua',
      navbar:'Українська',
    },
    RU: {
      text: 'Начать',
      lng: 'ru',
      navbar:'Русский',
    },
    EN: {
      text: 'English',
      lng: 'en',
      navbar:'English',
    },
    ME: {
      text: '',
      lng: 'me',
      navbar:'',
    },
  };
  
  export default LANGME;